<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="4" sm="4" md="2" lg="1">
          Start
        </v-col>
        <v-col cols="6" sm="6" md="3" lg="2">
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            v-model="params.start"
            :items="listWo"
            :rules="[v => !!v || 'Start is required']"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="4" sm="4" md="2" lg="1">
          End
        </v-col>
        <v-col cols="6" sm="8" md="3" lg="2">
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            v-model="params.end"
            :items="listWo"
            :rules="[v => !!v || 'End is required']"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="1">
          <v-btn color="success" @click="search"> <v-icon>mdi-magnify</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense item-key="woId">
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.totalAmount="{ item }">
          {{ formatPrice(item.totalAmount) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "query-kasbon-by-wo",
  data: () => ({
    params: {
      start: null,
      end: null,
    },
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "WO #",
        value: "woId",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Kasbon#",
        value: "kasbonNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Date",
        value: "kasbonDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Employee",
        value: "name",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Payment",
        value: "payment",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Total",
        value: "totalAmount",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Old Kasbon#",
        value: "oldKasbon",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 8,
        text: "Status",
        value: "totalInvoicePaidIDR",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    items: [],
    listWo: [],
  }),

  methods: {
    search() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("generalKasbon/queryKasbonByWo", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getWos() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("generalKasbon/getListWo")
        .then(response => {
          this.listWo = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getWos();
  },
};
</script>

<style></style>
